<template>
  <div>

    <base-template
        itemView="list"
        :text-button-add="$t('addBankStatement')"
        :columns="columns"
        :filters="filters"
        :items="bankTransactions"
        :context="bankTransactionsContext"
        :isActionColumnEnabled="true"
        :openViewWindow="openViewWindow"
        :allowAdd="false"
        :allowAddByUpload="!bankAccount._hasAPI"
        selectable="single"

        @fetch="fetchPayload = $event"
        @rowSelected="linkToPayment($event[0])"
        @addItemByUpload="bankStatementUploadModalShow=true"
    >
      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-bank-transaction
            :bank-transaction="item"
            :is-downloadable="false"
            :is-viewable="true"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
      <!--      &lt;!&ndash;  ===      List view      === &ndash;&gt;-->
      <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Link-->
          <button-link
              @click.native.stop="linkToPayment(item)"
              v-b-tooltip.hover.left="$t('Link to a payment')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <!--                &lt;!&ndash;          View&ndash;&gt;-->
          <!--                <button-view-->
          <!--                    @click.native.stop="showDetails(item)"-->
          <!--                    v-b-tooltip.hover.left="$t('View')"-->
          <!--                    :withIcon="true"-->
          <!--                    :withText="false"-->
          <!--                    :withBorder="false"-->
          <!--                    size="sm"-->
          <!--                />-->

          <!--                &lt;!&ndash;          Edit&ndash;&gt;-->
          <!--                <button-edit-->
          <!--                    @click.native="editPayment(item)"-->
          <!--                    v-b-tooltip.hover.left="capitalize($t('Edit'))"-->
          <!--                    :withIcon="true"-->
          <!--                    :withText="false"-->
          <!--                    :withBorder="false"-->
          <!--                    size="sm"-->
          <!--                />-->

          <!--                &lt;!&ndash;          Delete&ndash;&gt;-->
          <!--                <button-remove-->
          <!--                    @click.native.stop="deletePaymentAlert(item)"-->
          <!--                    v-b-tooltip.hover.left="capitalize($t('delete'))"-->
          <!--                    :withIcon="true"-->
          <!--                    :withText="false"-->
          <!--                    :withBorder="false"-->
          <!--                    size="sm"-->
          <!--                />-->

        </div>
      </template>


      <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
      <!--      &lt;!&ndash;  ===     Select view     === &ndash;&gt;-->
      <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
      <!--      <template #leftViewWindow="{item}">-->
      <!--        <payment-card-->
      <!--            :payment="item"-->
      <!--            :is-downloadable="false"-->
      <!--            :is-viewable="true"-->

      <!--            @click="showDetails(item)"-->
      <!--            @view="showDetails(item)"-->
      <!--        />-->
      <!--      </template>-->
      <!--      <template #windowView-->
      <!--                v-if="selectedItems.length">-->
      <!--        <div class="d-flex justify-content-between mb-1">-->
      <!--          <h4>{{ selectedItems[0].date|moment('LL') }} - {{ selectedItems[0]._beneficiaries.join(', ') }}</h4>-->
      <!--          <div>-->
      <!--            <icon icon="edit"-->
      <!--                  class="cursor-pointer"-->
      <!--                  @click.native="editPayment(selectedItems[0])"/>-->
      <!--            <icon icon="times"-->
      <!--                  class="cursor-pointer ml-1"-->
      <!--                  @click.native="showDetails(null)"/>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <payment-view-->
      <!--            :payment="selectedItems[0]"-->
      <!--        />-->

      <!--      </template>-->
    </base-template>

    <!-- modal bank statements by upload-->
    <modal-upload
        :singleFile.sync="bankStatementFile"

        :documentType="'bankStatement'"
        :title="capitalize($t('bankStatement'))"
        :isOpen.sync="bankStatementUploadModalShow"
        :accepted-types="notOnlyOfx?['ofx', 'pdf']:['ofx']"
    >
      <template #top>
        <div class="d-flex">
          <custom-switch
              :model.sync="notOnlyOfx"
              :displayLabel="false"
          />
          <span>{{ $t('AllowPdfFiles') }}</span>
        </div>
      </template>
    </modal-upload>

    <!-- modal bank statements-->
    <modal-bank-transaction
        v-if="currentBankTransaction != null"
        :bank-transaction.sync="currentBankTransaction"
        :bank-transaction-best-matchs="bankTransactionBestMatchs"
        :title="$t('bank reconciliation')"
        :isOpen.sync="bankTransactionModalShow"
        :display-bank-account="true"

        @submitValidated="submitValidatedBankTransactionLocal('payment')"
        @submitWithIncomingInvoice="submitValidatedBankTransactionLocal('incomingInvoice')"
        @submitWithOutgoingInvoice="submitValidatedBankTransactionLocal('outgoingInvoice')"
        @submitWithBankTransaction="submitValidatedBankTransactionLocal('bankTransaction')"
        @submitWithSalary="submitValidatedBankTransactionLocal('salary')"
        @submitWithCharge="submitValidatedBankTransactionLocal('charge')"
        @submitWithOther="submitValidatedBankTransactionLocal('other', $event)"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import i18n from '../../../../libs/i18n'
import store from '../../../../store'
import useAPI from '../../../../utils/useAPI'
import BaseTemplate from '../../../../components/base/Base'
import ModalUpload from '../../../../components/prompt/Upload'
import ModalBankTransaction from '../../../../components/prompt/BankTransaction'
import CardBankTransaction from '../../../../components/card/BankTransaction'
import ButtonLink from '../../../../components/button/Link'
import CustomSwitch from '../../../../components/input/Switch.vue'

export default {
  components: {
    BaseTemplate,
    ModalUpload,
    ModalBankTransaction,
    CardBankTransaction,
    ButtonLink,
    CustomSwitch
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const { fetchAutocompletePayments } = useAPI()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('date')),
        key: 'date',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('wording')),
        key: 'wording',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('paymentMethod')),
        key: 'paymentMethod',
        sortable: true,
        cell: {
          useName: true,
          translate: true,
        }
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          useBadge: true,
          type: 'bankTransactionPaymentRelationStatus',
          center: true
        }
      },
      {
        display: capitalize(i18n.tc('debit')),
        key: 'debit',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.tc('credit')),
        key: 'credit',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },

    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('debit')),
        key: '_filter_isDebit',
        searchCriteria: [{
          column_name: 'debit',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('credit')),
        key: '_filter_isCredit',
        searchCriteria: [{
          column_name: 'credit',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('reconciliated')),
        key: '_filter_isReconciliated',
        searchCriteria: [{
          column_name: 'bankTransaction_reconciliated',
          comparator: 'true',
        }],
      },
      {
        text: capitalize(i18n.tc('unreconciliated')),
        key: '_filter_isUnreconciliated',
        searchCriteria: [{
          column_name: 'bankTransaction_reconciliated',
          comparator: 'false',
        }],
      },
      {
        text: capitalize(i18n.tc('currentFiscalYear')),
        key: '_filter_isCurrentFiscalYear',
        searchCriteria: [{
          column_name: 'date',
          comparator: 'range',
          search_text_0: '2022-07-01',
          search_text_1: '2023-06-30'
        }],
        // selected: true,
      },

    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const bankStatementUploadModalShow = ref(false)
    const bankStatementFile = ref(null)
    const fetchPayload = ref({})
    const currentBankTransaction = ref(null)
    const bankTransactionBestMatchs = ref(null)
    const bankTransactionModalShow = ref(false)
    const notOnlyOfx = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankTransactions, bankTransactionsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(bankStatementFile, (val) => {
      if (val != null) {
        recognizeBankStatements(val)
            .then(response => {
              store.commit('bankTransaction/SET_BANKTRANSACTIONS', response.data.results)
              store.commit('bankTransaction/SET_BANKTRANSACTIONSCONTEXT', response.data.page_context)

              store.dispatch('bankAccount2/getBankAccountPayments', {bankAccountId: props.bankAccount.id})

              bankStatementFile.value = null
              bankStatementUploadModalShow.value = false
            })
            .catch(() => {
              bankStatementFile.value = null
              bankStatementUploadModalShow.value = false
            })
      }

    })

    watch(() => props.bankAccount, val => {
      getBankTransactions()
    })

    watch(fetchPayload, val => {
      fetchPayload.value.bankAccountId = props.bankAccount.id
      store.dispatch('bankAccount2/getBankAccountBankTransactions', fetchPayload.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getBankTransactions = () => {
      fetchPayload.value.bankAccountId = props.bankAccount.id
      store.dispatch('bankAccount2/getBankAccountBankTransactions', fetchPayload.value)
    }

    // const getPayments = () => {
    //   fetchPayload.value.bankAccountId = props.bankAccount.id
    //   store.dispatch('bankAccount2/getBankAccountPayments', fetchPayload.value)
    // }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const recognizeBankStatements = (bankStatementDocument) => {
      // console.log(bankStatementDocument)
      return new Promise((resolve, reject) => {
        store.dispatch('document/recognizeDocument', [bankStatementDocument, 'bankStatement'])
            .then(response => {
              // console.log(response)
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
      })
    }

    const linkToPayment = (bankTransaction) => {
      if (bankTransaction) {
        currentBankTransaction.value = JSON.parse(JSON.stringify(bankTransaction))

        fetchAutocompletePayments({
          searchCriteria: [
            {
              column_name: 'bankAccount',
              comparator: 'eq',
              search_text: props.bankAccount.id
            },
            {
              column_name: 'amount',
              comparator: 'eq',
              search_text: bankTransaction.debit ? bankTransaction.debit : bankTransaction.credit
            }]
        })

        store.dispatch('bankTransaction/getBankTransactionBestMatch', bankTransaction)
            .then(response => {
              bankTransactionBestMatchs.value = response
              bankTransactionModalShow.value = true
            })
      }
    }

    const submitValidatedBankTransactionLocal = (type, payload = null) => {
      let currentBankTransactionLocal = JSON.parse(JSON.stringify(currentBankTransaction.value))

      // console.log(type)
      // console.log(payload)
      if (type == 'payment') {

        currentBankTransaction.value.payments.forEach(payment => {
          payment.bankTransaction = currentBankTransaction.value.id

          store.dispatch('payment/updatePayment', payment)
        })

        store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

        resetModal()

        // bankTransactionModalShow.value = false
        // currentBankTransaction.value = null
        // bankTransactionBestMatchs.value = null

      } else if (type == 'incomingInvoice') {
        let payment = {
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: currentBankTransaction.value.paymentMethod,
          paymentRelations: [{
            amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : -currentBankTransaction.value.credit,
            // bankTransaction: null,
            incomingInvoice: bankTransactionBestMatchs.value.incomingInvoice,
            outgoingInvoice: null,
            salary: null,
            socialSecurityContribution: null
          }]
        }

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

      } else if (type == 'outgoingInvoice') {
        let payment = {
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: currentBankTransaction.value.paymentMethod,
          paymentRelations: [{
            amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : -currentBankTransaction.value.credit,
            // bankTransaction: null,
            incomingInvoice: null,
            outgoingInvoice: bankTransactionBestMatchs.value.outgoingInvoice,
            salary: null,
            socialSecurityContribution: null
          }]
        }

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

      } else if (type == 'bankTransaction') {
        let payment = {
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: bankTransactionBestMatchs.value.bankTransaction.paymentMethod,
          amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : -currentBankTransaction.value.credit,
          reference: bankTransactionBestMatchs.value.bankTransaction.bankAccount._display
        }

        let linkedPayment = {
          bankAccount: bankTransactionBestMatchs.value.bankTransaction.bankAccount,
          bankTransaction: bankTransactionBestMatchs.value.bankTransaction.id,
          date: bankTransactionBestMatchs.value.bankTransaction.date,
          paymentMethod: bankTransactionBestMatchs.value.bankTransaction.paymentMethod,
          amount: bankTransactionBestMatchs.value.bankTransaction.debit?bankTransactionBestMatchs.value.bankTransaction.debit:-bankTransactionBestMatchs.value.bankTransaction.credit,
          reference: props.bankAccount._display
        }

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

        store.dispatch('payment/addPayment', linkedPayment).then(response => {
          store.commit('payment/REMOVE_PAYMENT', response.data)
        })
      } else if (type == 'salary') {
        let payment = {
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: currentBankTransaction.value.paymentMethod,
          paymentRelations: [{
            amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : currentBankTransaction.value.credit,
            // bankTransaction: null,
            incomingInvoice: null,
            outgoingInvoice: null,
            salary: bankTransactionBestMatchs.value.salary,
            socialSecurityContribution: null
          }]
        }

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

      } else if (type == 'charge') {
        let payment = {
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: currentBankTransaction.value.paymentMethod,
          paymentRelations: [{
            amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : currentBankTransaction.value.credit,
            // bankTransaction: null,
            incomingInvoice: null,
            outgoingInvoice: null,
            salary: null,
            socialSecurityContribution: bankTransactionBestMatchs.value.charge
          }]
        }

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

      } else if (type == 'other') {
        // console.log(payload)
        let payment = {
          amount: currentBankTransaction.value.debit ? currentBankTransaction.value.debit : currentBankTransaction.value.credit,
          bankAccount: props.bankAccount,
          bankTransaction: currentBankTransaction.value.id,
          date: currentBankTransaction.value.date,
          paymentMethod: currentBankTransaction.value.paymentMethod,
          attachment: payload.attachment,
          attachmentCategory: payload.attachmentCategory
        }
        // console.log(currentBankTransaction.value)
        // console.log(payment)

        store.dispatch('payment/addPayment', payment).then(response => {
          currentBankTransactionLocal.payments = [response.data]
          store.commit('bankTransaction/UPDATE_BANKTRANSACTION', currentBankTransactionLocal)

          resetModal()
        })

        // updateBankTransaction(currentBankTransaction.value)
      }
    }

    const resetModal = () => {
      bankTransactionModalShow.value = false
      currentBankTransaction.value = null
      bankTransactionBestMatchs.value = null
    }

    const updateBankTransaction = (bankTransaction) => {
      return new Promise((resolve, reject) => {
        store.dispatch('bankTransaction/updateBankTransaction', bankTransaction)
            .then(response => {
              bankTransactionModalShow.value = false
              currentBankTransaction.value = null
              bankTransactionBestMatchs.value = null
            })
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    getBankTransactions()

    return {
      // Components
      capitalize,

      // Data
      columns,
      filters,
      openViewWindow,
      bankStatementUploadModalShow,
      bankStatementFile,
      fetchPayload,
      currentBankTransaction,
      bankTransactionBestMatchs,
      bankTransactionModalShow,
      notOnlyOfx,

      // Computed
      bankTransactions,
      bankTransactionsContext,

      // Methods
      rowSelected,
      linkToPayment,
      submitValidatedBankTransactionLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>