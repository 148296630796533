<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="sm"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseBankTransactionModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form
          @keydown.enter.stop.prevent=""
          @submit.prevent="handleSubmit(submitValidatedBankTransactionLocal)"
      >
        <form-bank-transaction-payment
            :bankTransaction.sync="bankTransactionLocal"
            :bank-transaction-best-matchs="bankTransactionBestMatchs"
            :display-bank-account="displayBankAccount"
            :formErrors="errors"

            @submit="submitValidatedBankTransactionLocal"
            @submitWithIncomingInvoice="$emit('submitWithIncomingInvoice')"
            @submitWithOutgoingInvoice="$emit('submitWithOutgoingInvoice')"
            @submitWithBankTransaction="$emit('submitWithBankTransaction')"
            @submitWithSalary="$emit('submitWithSalary')"
            @submitWithCharge="$emit('submitWithCharge')"
            @submitWithOther="$emit('submitWithOther', $event)"
        />

        <button ref="submitBankTransactionFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'
import { isObject } from '../../utils/utils'

import useAPI from '../../utils/useAPI'
import FormBankTransactionPayment from '../form/BankTransactionPayment'

export default {
  components: {
    ValidationObserver,
    FormBankTransactionPayment
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    },
    bankTransactionBestMatchs: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    formMaxAmount: {
      type: Number,
      default: null
    },
    displayBankAccount: {
      type: Boolean,
      default: false
    },

  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const bankTransactionLocal = ref(props.bankTransaction)
    const submitBankTransactionFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint, bankAccounts } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.isOpen, val => {
      isOpenLocal.value = val
    })
    watch(() => props.bankTransaction, val => {
      bankTransactionLocal.value = val
    })

    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(bankTransactionLocal, (val) => {
      emit('update:bankTransaction', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseBankTransactionModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitBankTransactionFormRef.value.click()
      }
    }

    const submitValidatedBankTransactionLocal = () => {
      emit('submitValidated', 'payment')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedBankTransactionLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      bankTransactionLocal,
      submitBankTransactionFormRef,
      observerRef,

      // Computed
      hideFooter,
      bankAccounts,

      // Methods
      tryCloseBankTransactionModal,
      submitValidatedBankTransactionLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // bankTransaction: function (val) {
    //   this.bankTransactionLocal = val
    // },
    // isOpen: function (val) {
    //   this.isOpenLocal = val
    // },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.modal-body {
  background-color: #f8f8f8;
}
</style>