<template>
  <div>

    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <div>
            <h3>{{ capitalize($tc('beneficiary:', payment._beneficiaries.length)) }}</h3>
            <div>{{ payment._beneficiaries.join(', ') }}</div>
          </div>
          <div class="my-auto">
            <h2
                class="text-right"
                :class="payment.amount <= 0?'text-success':'text-danger'"
            >
              {{ currency(payment.amount) }}
            </h2>

            <div>
              {{ $t('PaymentMethod:') }} {{ $t(payment.paymentMethod.name) }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12">

        <b-table-lite
            responsive
            :items="payment.paymentRelations"
            :fields="paymentRelationsTableHeader"
        >
          <template #cell(invoice)="data">
            <span
                v-if="data.item.incomingInvoice != null"
                :class="{'text-primary cursor-pointer': data.item.incomingInvoice.receipt!= null}"
                @click="downloadByUrl(data.item.incomingInvoice.receipt)"
            >
              {{ data.item.incomingInvoice._display }}
            </span>
            <span
                v-else-if="data.item.outgoingInvoice != null"
                class="text-primary cursor-pointer"
                @click="store.dispatch('outgoingInvoice/printOutgoingInvoice', data.item.outgoingInvoice)"
            >
              {{ data.item.outgoingInvoice._display }}
            </span>
            <span
                v-else-if="data.item.salary != null"
                class="text-primary cursor-pointer"
                @click="downloadByUrl(data.item.salary.paySlip)"
            >
              {{ data.item.salary.employee._display }}
            </span>
            <span
                v-else-if="data.item.socialSecurityContribution != null"
                class="text-primary cursor-pointer"
                @click="downloadByUrl(data.item.socialSecurityContribution.document)"
            >
              {{ data.item.socialSecurityContribution.supplier._display }}
            </span>
          </template>

          <template #cell(invoiceAmount)="data">
            <span v-if="data.item.incomingInvoice != null">
              {{ currency(data.item.incomingInvoice.totalAmount) }}
            </span>
            <span v-else-if="data.item.outgoingInvoice != null">
              {{ currency(data.item.outgoingInvoice.totalAmount) }}
            </span>
            <span v-else-if="data.item.salary != null">
              {{ currency(data.item.salary.net) }}
            </span>
            <span v-else-if="data.item.socialSecurityContribution != null">
              {{ currency(data.item.socialSecurityContribution.preTaxAmount) }}
            </span>
          </template>

          <template #cell(payment)="data">
            {{ currency(data.item.amount) }}
          </template>
        </b-table-lite>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import i18n from '../../libs/i18n'
import store from '../../store'

export default {
  components: {},
  props: {
    payment: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const paymentRelationsTableHeader = ref([
      {
        key: 'invoice',
        label: i18n.tc('invoice')
      },
      {
        key: 'invoiceAmount',
        label: i18n.t('invoiceAmount')
      },
      {
        key: 'payment',
        label: i18n.tc('payment')
      },

    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const downloadByUrl = (item) => {
      if ('url' in item) {
        let link = document.createElement('a')
        link.href = item.url
        link.target = '_blank'
        link.download = item._display
        link.click()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      store,

      // Data
      paymentRelationsTableHeader,

      // Computed

      // Methods
      downloadByUrl,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>