<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="false"
      :isViewable="isViewable"
      @click="$emit('click')"
      @view="$emit('view')"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          variant="light-primary"
          class="my-auto"
      >
        <icon
            :icon="iconPaymentType"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <div class="d-flex justify-content-between">
        <h6 class="transaction-title text-truncate">
          {{ bankTransaction.wording }}
        </h6>

        <small
            v-if="displayBankAccount"
            :class="bankTransaction.credit?'text-success':'text-danger'"
        >{{ currency(bankTransaction.debit ? bankTransaction.debit : bankTransaction.credit) }}
        </small>
      </div>
      <div class="d-flex justify-content-between text-truncate">
        <small v-if="displayBankAccount">{{ bankTransaction.bankAccount._display }}</small>
        <small v-else>
          <i>{{ bankTransaction.date | moment('L') }}</i>
        </small>

        <small v-if="displayBankAccount">
          <i>{{ bankTransaction.date | moment('L') }}</i>
        </small>
        <small
            v-else
            :class="bankTransaction.credit?'text-success':'text-danger'"
        >{{ currency(bankTransaction.debit ? bankTransaction.debit : bankTransaction.credit) }}
        </small>
      </div>
    </template>

    <!--    <template #bodyContent>-->
    <!--      <h6 class="transaction-title text-truncate">-->
    <!--        {{ bankTransaction.wording }}-->
    <!--      </h6>-->
    <!--      <div class="d-flex justify-content-between text-truncate">-->
    <!--        <small>-->
    <!--          <i>{{ bankTransaction.date | moment('L') }}</i>-->
    <!--        </small>-->
    <!--        <small-->
    <!--            :class="bankTransaction.credit?'text-success':'text-danger'"-->
    <!--        >{{ currency(bankTransaction.debit?bankTransaction.debit:bankTransaction.credit) }}-->
    <!--        </small>-->
    <!--      </div>-->
    <!--    </template>-->

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '../../../utils/filter'

import GenericContent from './Content'
import i18n from '@/libs/i18n'

export default {
  components: {
    GenericContent
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    displayBankAccount: {
      type: Boolean,
      default: false
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconPaymentType = computed(() => {
      if (
          props.bankTransaction.paymentMethod.name == 'Bank transfert' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Bank transfert')
      ) {
        return 'exchange-alt'
      } else if (props.bankTransaction.paymentMethod.name == 'Direct debit' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Direct debit')) {
        return 'sign-out-alt'
      } else if (props.bankTransaction.paymentMethod.name == 'Debit card' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Debit card')) {
        return 'credit-card'
      } else if (props.bankTransaction.paymentMethod.name == 'Check' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Check')) {
        return 'money-check'
      } else if (props.bankTransaction.paymentMethod.name == 'Cash' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Cash')) {
        return 'money-bill-wave'
      } else if (props.bankTransaction.paymentMethod.name == 'Internal bank transfer' ||
          props.bankTransaction.paymentMethod.name == i18n.t('Internal bank transfer')) {
        return 'project-diagram'
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      iconPaymentType,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>