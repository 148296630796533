<template>

  <generic-card
      :byContent="true"

      @click="$emit('click')"
      @delete="$emit('deletePayment')"

  >
    <template #content>
      <content-payment
          :payment="payment"
          :isParentMethodPriority="true"
          :isPayable="isPayable"
          :isDownloadable="isDownloadable"
          :isViewable="isViewable"
          :isDeletable="false"
          :is-selected="isSelected"
          @view="$emit('view')"
          @pay="$emit('pay')"
      />
    </template>

  </generic-card>

<!--  <generic-card-->
<!--      :isLinkable="false"-->
<!--      :isEditable="isEditable"-->
<!--      :isPayable="isPayable"-->
<!--      :isDeletable="isDeletable"-->
<!--      :isDownloadable="isDownloadable"-->
<!--      :isViewable="isViewable"-->
<!--      @click="$emit('click')"-->
<!--      @edit="$emit('edit')"-->
<!--      @download="$emit('download')"-->
<!--      @delete="$emit('delete')"-->
<!--  >-->
<!--    <template #content>-->
<!--      <content-payment-->
<!--          :payment="payment"-->
<!--          :isParentMethodPriority="true"-->
<!--          :isPayable="isPayable"-->
<!--          :isDownloadable="isDownloadable"-->
<!--          :isViewable="isViewable"-->
<!--          :isDeletable="false"-->
<!--          @view="$emit('view')"-->
<!--          @pay="$emit('pay')"-->
<!--      />-->
<!--    </template>-->

<!--  </generic-card>-->
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentPayment from './components/PaymentContent2'

export default {
  components: {
    GenericCard,
    ContentPayment
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>