<template>
  <div>
    <b-row>
      <b-col cols="12">
        <bank-transaction-card
            :bank-transaction="bankTransaction"
            :is-downloadable="false"
            class="mb-2 bg-primary bg-lighten-5"
        />

        <div
            v-if="collapseVisibility == ''"
            class="text-center text-warning font-weight-bolder mb-2"
        >
          {{ $t('No match found') }}
        </div>
      </b-col>


      <b-col cols="12">
        <collapse
            accordion
            type="margin"
            class="mb-2"
        >

          <collapse-item
              :title="capitalize($tc('payment'))"
              :is-visible="collapseVisibility == 'payment'"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length != 0)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length != 0">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate"
                    v-b-tooltip="$tc('BestMatch', (bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length==0)?1:2)">{{ $tc('BestMatch:', (bankTransactionBestMatchs.payments != null && bankTransactionBestMatchs.payments.length==0)?1:2) }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <payment-card
                  v-for="payment in bankTransactionBestMatchs.payments"
                  :payment="payment"
                  :is-downloadable="false"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('customerInvoice'))"
              :is-visible="collapseVisibility == 'customerInvoice'"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.outgoingInvoice != null)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.outgoingInvoice != null">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate"
                    v-b-tooltip="$tc('BestMatch')">{{ $tc('BestMatch:') }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <outgoing-invoice-card
                  :outgoing-invoice="bankTransactionBestMatchs.outgoingInvoice"
                  :is-downloadable="false"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('supplierInvoice'))"
              :is-visible="collapseVisibility == 'supplierInvoice'"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.incomingInvoice != null)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.incomingInvoice != null">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate mb-0"
                    v-b-tooltip="$tc('BestMatch')">{{ $tc('BestMatch:') }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <incoming-invoice-card
                  :incoming-invoice="bankTransactionBestMatchs.incomingInvoice"
                  :is-downloadable="false"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('bankTransaction'))"
              :is-visible="collapseVisibility == 'bankTransaction'"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.bankTransaction != null)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div
                v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.bankTransaction != null">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate"
                    v-b-tooltip="$tc('BestMatch')">{{ $tc('BestMatch:') }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <bank-transaction-card
                  :bank-transaction="bankTransactionBestMatchs.bankTransaction"
                  :display-bank-account="displayBankAccount"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('salary'))"
              :is-visible="collapseVisibility == 'salary'"
              collapseClass="bg-success bg-lighten-5"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.salary != null)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.salary != null">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate"
                    v-b-tooltip="$tc('BestMatch')">{{ $tc('BestMatch:') }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <salary-card
                  :salary="bankTransactionBestMatchs.salary"
                  :is-downloadable="false"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('charge'))"
              :is-visible="collapseVisibility == 'charge'"
              :collapseClass="((bankTransactionBestMatchs && bankTransactionBestMatchs.charge != null)?'bg-success':'bg-warning') +' bg-lighten-5'"
          >
            <div v-if="bankTransactionBestMatchs && bankTransactionBestMatchs.charge != null">
              <div class="d-flex justify-content-between">
                <h4 class="text-truncate"
                    v-b-tooltip="$tc('BestMatch')">{{ $tc('BestMatch:') }}</h4>
                <icon
                    v-b-hover="handleHover"
                    class="my-auto px-1 cursor-pointer"
                    :class="{'text-success': isBestpaymentHovered}"
                    icon="check-circle"

                    @click.native="bestPaymentSelected"
                />
              </div>
              <charge-card
                  :charge-taxe="bankTransactionBestMatchs.charge"
                  :is-downloadable="false"
                  @click.native="bestPaymentSelected"
              />
            </div>
            <div v-else>
              {{ $t('No match found') }}
            </div>
          </collapse-item>

          <collapse-item
              :title="capitalize($tc('other'))"
              :is-visible="collapseVisibility == 'other'"
              collapseClass="bg-secondary bg-lighten-5"
          >

            <validation-observer
                ref="observerRef"
                #default="{handleSubmit, errors}">
              <b-form
                  @keydown.enter.stop.prevent=""
                  @submit.prevent="handleSubmit(submitValidatedLocal)"
              >
                <select2
                    :placeholder="$t('ChooseADocumentCategory')"
                    name="attachmentCategory"
                    :model.sync="otherRelation.attachmentCategory"
                    :options="autocompleteDocumentCategories"
                    :is-required="true"
                    label="name"
                />

                <field-file
                    name="attachment"
                    :display-label="true"
                    :singleFile.sync="otherRelation.attachment"
                    :accepted-types="['pdf', 'image']"
                    :is-required="true"
                    document-type="bankTransactionAttachment"
                />
                <button-validate
                    class="float-right mb-1"
                    @click="handleSubmit(submitValidatedLocal)"
                />

              </b-form>
            </validation-observer>


          </collapse-item>
        </collapse>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import BankTransactionCard from '../card/BankTransaction'
import IncomingInvoiceCard from '../card/IncomingInvoice2'
import OutgoingInvoiceCard from '../card/OutgoingInvoice2'
import PaymentCard from '../card/Payment'
import SalaryCard from '../card/Salary2'
import ChargeCard from '../card/ChargeTaxe'
import FieldSelectPayment from '../input/custom/SelectPayment'
import BankTransaction from '../prompt/BankTransaction'
import Collapse from '../collapse/Collapse'
import CollapseItem from '../collapse/CollapseItem'
import { ValidationObserver } from 'vee-validate'
import Select2 from '../input/Select2.vue'
import FieldFile from '../input/File.vue'
import ButtonValidate from '../button/Validate.vue'

export default {
  components: {
    BankTransaction,
    BankTransactionCard,
    IncomingInvoiceCard,
    OutgoingInvoiceCard,
    PaymentCard,
    SalaryCard,
    ChargeCard,
    FieldSelectPayment,
    Collapse,
    CollapseItem,
    ValidationObserver,
    Select2,
    FieldFile,
    ButtonValidate
  },
  props: {
    bankTransaction: {
      type: Object,
      default: () => {}
    },
    bankTransactionBestMatchs: {
      type: Object,
      default: () => {}
    },
    displayBankAccount: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isBestpaymentHovered = ref(false)
    const otherRelation = ref({
      attachmentCategory: null,
      attachment: null
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompletePayments, autocompleteDocumentCategories } = useAPI()

    const collapseVisibility = computed(() => {
      if (props.bankTransactionBestMatchs) {
        if (props.bankTransactionBestMatchs.payments != null) {
          return 'payment'
        } else if (props.bankTransactionBestMatchs.outgoingInvoice != null) {
          return 'customerInvoice'
        } else if (props.bankTransactionBestMatchs.incomingInvoice != null) {
          return 'supplierInvoice'
        } else if (props.bankTransactionBestMatchs.bankTransaction != null) {
          return 'bankTransaction'
        } else if (props.bankTransactionBestMatchs.salary != null) {
          return 'salary'
        } else if (props.bankTransactionBestMatchs.charge != null) {
          return 'charge'
        } else if (props.bankTransactionBestMatchs.other != null) {
          return 'other'
        }
      }

      return ''
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = (hovered) => {
      isBestpaymentHovered.value = hovered
    }

    const bestPaymentSelected = () => {
      // console.log(props.bankTransactionBestMatchs)
      if (props.bankTransactionBestMatchs.payments != null) {
        props.bankTransaction.payments = props.bankTransactionBestMatchs.payments
        emit('submit')
      } else if (props.bankTransactionBestMatchs.incomingInvoice != null) {
        emit('submitWithIncomingInvoice')
      } else if (props.bankTransactionBestMatchs.outgoingInvoice != null) {
        emit('submitWithOutgoingInvoice')
      } else if (props.bankTransactionBestMatchs.bankTransaction != null) {
        emit('submitWithBankTransaction')
      } else if (props.bankTransactionBestMatchs.salary != null) {
        emit('submitWithSalary')
      } else if (props.bankTransactionBestMatchs.charge != null) {
        emit('submitWithCharge')
      }

    }

    const submitValidatedLocal = () => {
      emit('submitWithOther', otherRelation.value)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isBestpaymentHovered,
      otherRelation,

      // Computed
      handleHover,
      autocompletePayments,
      autocompleteDocumentCategories,
      collapseVisibility,

      // Methods
      bestPaymentSelected,
      submitValidatedLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>