<template>
  <div>

    <!--    Bank account cards-->
    <b-row>
      <b-col
          md="4"
          v-for="(ba, index) in bankAccounts2"
          :key="index"
      >
        <bank-account-card
            :bank-account="ba"
            :is-selected="selectedBankAccount == ba"

            @click="selectedBankAccount = ba"
        />

      </b-col>
    </b-row>

    <!--    Tabs-->
    <b-row v-if="selectedBankAccount != null">
      <b-col>
        <b-tabs justified>
          <b-tab>
            <template #title>
              <icon icon="chart-line"/>
              <span>{{ $t('Dashboard') }}</span>
            </template>
            <bank-account-dashboard :bank-account="selectedBankAccount"/>

          </b-tab>
          <b-tab active>
            <template #title>
              <icon icon="cash-register"/>
              <span>{{ $t('CashFlowJournal') }}</span>
            </template>
            <cash-flow-journal :bank-account="selectedBankAccount"/>

          </b-tab>
          <b-tab>
            <template #title>
              <icon icon="file-medical-alt"/>
              <span>{{ $t('BankStatements') }}</span>
            </template>
            <bank-account-bank-transactions :bank-account="selectedBankAccount"/>

          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import BankAccountCard from '../../../components/card/BankAccount'
import BankAccountDashboard from './components/Dashboard'
import BankAccountCashFlowJournal from './components/CashFlowJournal'
import BankAccountBankTransactions from './components/BankTransactions'
import CashFlowJournal from './components/CashFlowJournal'

export default {
  components: {
    CashFlowJournal,
    BankAccountCard,
    BankAccountDashboard,
    BankAccountCashFlowJournal,
    BankAccountBankTransactions
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedBankAccount = ref(null)
    // const detailedBankAccount = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccounts2 } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(selectedBankAccount, val => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchBankAccounts2, fetchAutocompleteDocumentCategories } = useAPI()

    // const selectBankAccount = (bankAccount) => {
    //
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchBankAccounts2()
        .then(() => {
          if (bankAccounts2.value.length) {
            selectedBankAccount.value = bankAccounts2.value[0]
          }

          fetchAutocompleteDocumentCategories()
        })

    return {
      // Components

      // Data
      selectedBankAccount,

      // Computed
      bankAccounts2,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>